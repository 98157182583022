/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { FC, Fragment, useState } from 'react';
import UndoIcon from '../assets/undo.svg';
import ChevronLeftIcon from '../assets/chevron_left.svg';
import { AmountSettings, ExerciseSettings } from '../utils/types';
import { AddButtonProps, AddButtons } from './AddButtons';
import { Button } from './Button';
import { AmountPopup } from './AmountPopup';

export const MainButtons: FC<{
  waterSettings: AmountSettings;
  addWater: (a: number) => void;
  nutritionSettings: AmountSettings;
  addNutrition: (a: number) => void;
  exerciseSettings: ExerciseSettings;
  addExercise: (t: string) => void;
  undo?: () => void;
}> = ({ waterSettings, addWater, nutritionSettings, addNutrition, exerciseSettings, addExercise, undo }) => {
  const [childButtons, setChildButtons] = useState<AddButtonProps[] | undefined>(undefined);
  const [nutritionPopupOpen, setNutritionPopupOpen] = useState(false);

  return (
    <Fragment>
      <div
        css={css`
          position: fixed;
          bottom: 0;
          right: 0;
          margin-right: 1rem;
          margin-bottom: 2rem;
          padding-bottom: env(safe-area-inset-bottom);
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          align-items: flex-end;
          pointer-events: none;
        `}
      >
        <AddButtons
          options={
            childButtons || [
              {
                text: 'Water',
                onClick: () =>
                  setChildButtons(
                    waterSettings.amounts.map((a) => ({
                      text: `${a * 1000} ${waterSettings.amountDisplayUnit}`,
                      onClick: () => {
                        addWater(a);
                        setChildButtons(undefined);
                      },
                    })),
                  ),
              },
              { text: 'Nutrition', onClick: () => setNutritionPopupOpen(true) },
              {
                text: 'Exercise',
                onClick: () =>
                  setChildButtons(
                    Object.keys(exerciseSettings).map((t) => ({
                      text: t,
                      onClick: () => {
                        addExercise(t);
                        setChildButtons(undefined);
                      },
                    })),
                  ),
              },
            ]
          }
        />
        {childButtons ? (
          <Button
            key="back-button"
            onClick={() => {
              setChildButtons(undefined);
            }}
            css={css`
              height: 2.5rem;
              width: 2.5rem;
              margin-right: 0.75rem;
              pointer-events: initial;
            `}
          >
            <img
              src={ChevronLeftIcon}
              alt="Back"
              title="Back"
              css={css`
                height: 1.5rem;
                width: 1.5rem;
                display: block;
              `}
            />
          </Button>
        ) : (
          !!undo && (
            <Button
              key="undo-button"
              onClick={undo}
              css={css`
                height: 2.5rem;
                width: 2.5rem;
                margin-right: 0.75rem;
                pointer-events: initial;
              `}
            >
              <img
                src={UndoIcon}
                alt="Undo"
                title="Undo"
                css={css`
                  height: 1.5rem;
                  width: 1.5rem;
                  display: block;
                `}
              />
            </Button>
          )
        )}
      </div>
      {nutritionPopupOpen && (
        <AmountPopup
          title="Add nutrition entry"
          settings={nutritionSettings}
          onAdd={addNutrition}
          onClose={() => setNutritionPopupOpen(false)}
        />
      )}
    </Fragment>
  );
};
