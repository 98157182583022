/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { FC } from 'react';
import { Button } from './Button';

export type AddButtonProps = { text: string; onClick: () => void };

export const AddButtons: FC<{
  options: AddButtonProps[];
}> = ({ options }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-items: flex-end;
        align-items: flex-end;
      `}
    >
      {options.map((a) => (
        <Button
          key={`button-${a.text}`}
          onClick={a.onClick}
          css={css`
            margin-top: 0.5rem;
            min-height: 2.5rem;
            pointer-events: initial;
          `}
        >
          {a.text}
        </Button>
      ))}
    </div>
  );
};
