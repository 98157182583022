/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { formatFloat } from '../utils/number';
import { AmountSettings } from '../utils/types';
import { Button } from './Button';

export const AmountPopup: FC<{
  title: string;
  settings: AmountSettings;
  onAdd: (v: number) => void;
  onClose: () => void;
}> = ({ title, settings, onAdd, onClose }) => {
  const rootEl = document.getElementById('root');
  const divider = 100;
  const [per100, setPer100] = useState('');
  const [value, setValue] = useState('100');

  useEffect(() => {
    const body = document.body;
    if (body) {
      body.classList.add('no-scroll');
      return () => body.classList.remove('no-scroll');
    }
  }, []);

  if (!rootEl) {
    return null;
  }

  let result = 0;
  const per100Num = parseFloat(per100.replace(',', '.'));
  const valueNum = parseFloat(value.replace(',', '.'));
  if (!isNaN(valueNum) && valueNum > 0 && !isNaN(per100Num) && per100Num > 0) {
    // Round to max 3 decimals
    result = Math.round(per100Num * valueNum * 10) / (10 * divider);
  }

  return ReactDOM.createPortal(
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
      `}
    >
      <div
        aria-hidden="true"
        onClick={onClose}
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: var(--inverse-color);
          opacity: 0.5;
        `}
      />
      <div
        role="dialog"
        css={css`
          position: relative;
          top: calc(50% - 6rem);
          left: 0;
          margin: 0 auto;
          width: 15rem;
          border: 1px solid var(--main-color);
          border-radius: 1rem;
          background-color: var(--inverse-color);
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
          padding: 1rem;
        `}
      >
        <form
          noValidate
          autoComplete="off"
          action="none"
          onSubmit={(e) => {
            e.preventDefault();

            if (result) {
              onAdd(settings.amountConversion(result));
              onClose();
            }
          }}
        >
          <h2
            css={css`
              font-weight: 700;
              font-size: 1.25rem;
              margin: 0 0 1rem;
            `}
          >
            {title}
          </h2>
          <div
            css={css`
              margin-bottom: 0.5rem;
            `}
          >
            <input
              id="per100"
              type="text"
              value={per100}
              onChange={(e) => setPer100(e.target.value)}
              css={css`
                height: 2.5rem;
                font-size: 1rem;
                font-family: var(--font-family);
                border-radius: 0.5rem;
                width: 6rem;
                padding: 0.5rem 0.75rem;
              `}
            />
            <label htmlFor="per100">{` / ${divider} ${settings.amountDisplayUnit}`}</label>
          </div>
          <div>
            <input
              id="amount"
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              css={css`
                height: 2.5rem;
                font-size: 1rem;
                font-family: var(--font-family);
                border-radius: 0.5rem;
                width: 6rem;
                padding: 0.5rem 0.75rem;
              `}
            />
            <label htmlFor="amount">{` ${settings.amountDisplayUnit}`}</label>
          </div>
          <p
            css={css`
              margin: 0.5rem 0 1rem;
            `}
          >{`Result: ${formatFloat(result)} ${settings.amountDisplayUnit}`}</p>
          <div
            css={css`
              display: flex;
              justify-content: center;
            `}
          >
            <Button
              type="button"
              onClick={onClose}
              css={css`
                margin-right: 1rem;
                background-color: var(--inverse-color);
                color: var(--main-color);
              `}
            >
              Cancel
            </Button>
            <Button type="submit">Add</Button>
          </div>
        </form>
      </div>
    </div>,
    rootEl,
  );
};
