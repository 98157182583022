import { useEffect, useState, useRef } from 'react';

export function useStoredState<T extends Record<string, unknown> | unknown[]>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(initialValue);
  const firstLoad = useRef(true);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      try {
        const item = window.localStorage.getItem(key);
        if (item) {
          const parsed = JSON.parse(item);
          const combined = Array.isArray(initialValue)
            ? parsed
            : {
                ...initialValue,
                ...parsed,
              };
          setStoredValue(combined);
          window.localStorage.setItem(key, JSON.stringify(combined));
        } else {
          window.localStorage.setItem(key, JSON.stringify(initialValue));
        }
      } catch (error) {
        window.localStorage.setItem(key, JSON.stringify(initialValue));
      }
    }
  }, [key, initialValue]);

  const setValue: typeof setStoredValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
      setStoredValue(valueToStore);
    } catch (error) {
      // no-op for now: if storing to localStorage fails, state won't change either
    }
  };

  return [storedValue, setValue] as const;
}
