/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { FC, Fragment, ReactChild, useState } from 'react';
import ChevronDownIcon from '../assets/chevron_down.svg';

export const ShowAll: FC<{ content: ReactChild[] }> = ({ content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <button
        onClick={() => setIsOpen(!isOpen)}
        css={css`
          display: inline-block;
          cursor: pointer;
          font-size: 1rem;
          font-family: var(--font-family);
          font-weight: 700;
          border: none;
          background: transparent;
          color: var(--highlight-color);
          padding: 0rem;
          -webkit-tap-highlight-color: transparent;

          &:hover,
          &:focus-visible,
          &:not(:focus-visible):focus {
            outline: 0;
            box-shadow: 0 1px 0 0 var(--highlight-color);
          }

          &:active {
            opacity: 0.8;
          }
        `}
      >
        <span
          css={css`
            display: inline-block;
          `}
        >
          show all
        </span>
        <img
          src={ChevronDownIcon}
          alt=""
          aria-hidden="true"
          css={css`
            height: 1.1rem;
            width: 1.1rem;
            display: inline-block;
            vertical-align: bottom;
            margin-left: 0.125rem;
            margin-right: -0.1875rem;
            ${isOpen &&
            css`
              transform: rotate(180deg);
            `}
          `}
        />
      </button>
      {isOpen && (
        <div
          css={css`
            padding: 0 0.5rem;
          `}
        >
          {content}
        </div>
      )}
    </Fragment>
  );
};
