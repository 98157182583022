/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { FC } from 'react';
import { AllData } from '../utils/types';
import { Button } from './Button';
import { ContentBlock } from './ContentBlock';
import DownloadIcon from '../assets/download.svg';

export const DataExport: FC<{ data: AllData; resetData: () => void; resetSettings: () => void }> = ({
  data,
  resetData,
  resetSettings,
}) => {
  return (
    <ContentBlock title="Data">
      <p>
        <span>Export all stored data: </span>
        <a
          href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data, null, 2))}`}
          download="mini-tracking-data.json"
          css={css`
            color: var(--highlight-color);
            font-weight: 700;
            text-decoration: none;

            &:hover,
            &:focus-visible,
            &:not(:focus-visible):focus {
              outline: 0;
              box-shadow: 0 1px 0 0 var(--highlight-color);
            }

            :active {
              opacity: 0.8;
            }
          `}
        >
          <img
            src={DownloadIcon}
            alt=""
            aria-hidden="true"
            css={css`
              height: 1.1rem;
              width: 1.1rem;
              display: inline-block;
              vertical-align: top;
              margin-right: 0.25rem;
              margin-left: -0.1875rem;
            `}
          />
          <span>download</span>
        </a>
      </p>
      <p
        css={css`
          display: flex;
          margin-top: 1rem;
        `}
      >
        <Button
          onClick={() => {
            const confirmed = window.confirm('Delete all stored data?');
            if (confirmed) {
              resetData();
            }
          }}
          css={css`
            margin-right: 0.5rem;
          `}
        >
          Reset data
        </Button>
        <Button onClick={() => resetSettings()}>Reset settings</Button>
      </p>
    </ContentBlock>
  );
};
