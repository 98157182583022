/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import React, { FC, Fragment, useState } from 'react';
import { useStoredState } from '../utils/storage';
import { AmountEntry, AmountSettings, ExerciseEntry, ExerciseSettings } from '../utils/types';
import { AmountTracking } from './AmountTracking';
import { DataExport } from './DataExport';
import { ExerciseTracking } from './ExerciseTracking';
import { MainButtons } from './MainButtons';

const defaultWater: AmountSettings = {
  goal: 2,
  goalUnit: 'l',
  amounts: [0.15, 0.25],
  amountConversion: (v) => 1000 * v,
  amountDisplayUnit: 'ml',
};
const defaultNutrition: AmountSettings = {
  goal: 95,
  goalUnit: 'g',
  amounts: [],
  amountConversion: (v) => v,
  amountDisplayUnit: 'g',
};
const defaultExercise: ExerciseSettings = {
  move: 5,
  muscle: 2,
};

export const App: FC = () => {
  const [waterSettings, setWaterSettings] = useStoredState<AmountSettings>('water-prefs', defaultWater);
  const [waterEntries, setWaterEntries] = useStoredState<AmountEntry[]>('water-entries', []);
  const [nutritionSettings, setNutritionSettings] = useStoredState<AmountSettings>('nutrition-prefs', defaultNutrition);
  const [nutritionEntries, setNutritionEntries] = useStoredState<AmountEntry[]>('nutrition-entries', []);
  const [exerciseSettings, setExerciseSettings] = useStoredState<ExerciseSettings>('exercise-prefs', defaultExercise);
  const [exerciseEntries, setExerciseEntries] = useStoredState<ExerciseEntry[]>('exercise-entries', []);

  const [undoables, setUndoables] = useState<Array<'water' | 'nutrition' | 'exercise'>>([]);
  const toUndo = undoables[undoables.length - 1];

  return (
    <Fragment>
      <Global
        styles={css`
          @font-face {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url('/static/fonts/Roboto-Regular.ttf') format('truetype');
          }
          @font-face {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-display: swap;
            src: url('/static/fonts/Roboto-Bold.ttf') format('truetype');
          }
          :root {
            --main-color: #eef8ff;
            --inverse-color: #002d4c;
            --highlight-color: #00d9e0;
            --font-family: Roboto, sans-serif;
            --code-font-family: monospace;
          }
          html {
            font-family: var(--font-family);
            font-style: normal;
            font-size: 16px;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            color: var(--main-color);
          }
          body {
            margin: 0;
            position: relative;
            background-color: var(--inverse-color);
            display: flex;
            flex-direction: column;
            min-height: 100vh;
            &.no-scroll {
              overflow: hidden;
            }
          }
          #root {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
          }
          * {
            box-sizing: border-box;
          }
        `}
      />
      <AmountTracking key="water" title="Water" entries={waterEntries} settings={waterSettings} />
      <AmountTracking key="nutrition" title="Nutrition" entries={nutritionEntries} settings={nutritionSettings} />
      <ExerciseTracking entries={exerciseEntries} settings={exerciseSettings} />
      <DataExport
        data={{ waterEntries, waterSettings, nutritionEntries, nutritionSettings, exerciseEntries, exerciseSettings }}
        resetData={() => {
          setWaterEntries([]);
          setNutritionEntries([]);
          setExerciseEntries([]);
        }}
        resetSettings={() => {
          setWaterSettings(defaultWater);
          setNutritionSettings(defaultNutrition);
          setExerciseSettings(defaultExercise);
        }}
      />
      <div
        css={css`
          margin-bottom: 10rem;
        `}
      />
      <MainButtons
        waterSettings={waterSettings}
        nutritionSettings={nutritionSettings}
        exerciseSettings={exerciseSettings}
        addWater={(a) => {
          setWaterEntries((es) => [...es, { timestamp: Date.now(), amount: a }]);
          setUndoables((es) => [...es, 'water']);
        }}
        addNutrition={(a) => {
          setNutritionEntries((es) => [...es, { timestamp: Date.now(), amount: a }]);
          setUndoables((es) => [...es, 'nutrition']);
        }}
        addExercise={(t) => {
          setExerciseEntries((es) => [...es, { timestamp: Date.now(), type: t }]);
          setUndoables((es) => [...es, 'exercise']);
        }}
        undo={
          toUndo &&
          (() => {
            switch (toUndo) {
              case 'water':
                setWaterEntries((es) => es.slice(0, -1));
                break;
              case 'nutrition':
                setNutritionEntries((es) => es.slice(0, -1));
                break;
              case 'exercise':
                setExerciseEntries((es) => es.slice(0, -1));
                break;
              default:
                break;
            }
            setUndoables((es) => es.slice(0, -1));
          })
        }
      />
    </Fragment>
  );
};
