/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { FC } from 'react';
import { isToday, timeStr } from '../utils/datetime';
import { formatFloat } from '../utils/number';
import type { AmountEntry, AmountSettings } from '../utils/types';
import { ContentBlock } from './ContentBlock';

export const AmountTracking: FC<{
  title: string;
  entries: AmountEntry[];
  settings: AmountSettings;
}> = ({ title, entries, settings }) => {
  const todayEntries = entries.filter((e) => isToday(e.timestamp));
  const currentSum = todayEntries.reduce((v, e) => v + e.amount, 0);
  const percentage = Math.round((currentSum / settings.goal) * 100);
  const latestEntry = entries[entries.length - 1];

  return (
    <ContentBlock
      title={title}
      headerAddition="today"
      latestEntry={
        latestEntry && {
          timestamp: latestEntry.timestamp,
          description: `${formatFloat(settings.amountConversion(latestEntry.amount))} ${settings.amountDisplayUnit}`,
        }
      }
      showAllContent={[...todayEntries].reverse().map((e) => (
        <div key={`amount-${title}-${e.timestamp}`}>
          <strong
            css={css`
              margin-right: 0.5rem;
            `}
          >
            {timeStr(e.timestamp)}
          </strong>
          <span>{` ${formatFloat(settings.amountConversion(e.amount))} ${settings.amountDisplayUnit}`}</span>
        </div>
      ))}
    >
      <p
        css={css`
          margin: 0 0 0.5rem;
          font-size: 1.25rem;
        `}
      >
        <span
          css={css`
            font-weight: 700;
          `}
        >{`${formatFloat(currentSum)} ${settings.goalUnit} / ${formatFloat(settings.goal)} ${
          settings.goalUnit
        } `}</span>
        <span>{`(${percentage} %)`}</span>
      </p>
    </ContentBlock>
  );
};
