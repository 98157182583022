/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { FC, ReactChild } from 'react';
import { dateTimeStr, isToday, timeStr } from '../utils/datetime';
import { ShowAll } from './ShowAll';

export const ContentBlock: FC<{
  title: string;
  headerAddition?: string;
  latestEntry?: { timestamp: number; description: string };
  showAllContent?: ReactChild[];
}> = ({ title, headerAddition, latestEntry, showAllContent, children }) => (
  <section
    css={css`
      margin: 1rem;
      :not(:first-of-type) {
        margin-top: 0.25rem;
        padding-top: 1rem;
        border-top: 1px solid var(--highlight-color);
      }
    `}
  >
    <header
      css={css`
        margin-bottom: 0.75rem;
      `}
    >
      <h1
        css={css`
          display: inline;
          font-weight: 700;
          font-size: 1.75rem;
          margin: 0;
        `}
      >
        {title}
        {!!headerAddition && ' '}
      </h1>
      {!!headerAddition && (
        <span
          css={css`
            color: var(--highlight-color);
          `}
        >
          {headerAddition}
        </span>
      )}
    </header>
    {children}
    {!!latestEntry && (
      <p
        css={css`
          margin: 0.5rem 0 0;
          line-height: 1.5;
        `}
      >
        <span>Latest at </span>
        <span>
          <span
            css={css`
              font-weight: 700;
            `}
          >
            {isToday(latestEntry.timestamp) ? timeStr(latestEntry.timestamp) : dateTimeStr(latestEntry.timestamp)}
          </span>
          <span
            css={css`
              margin-right: 0.5rem;
            `}
          >{` (${latestEntry.description})`}</span>
          {!!showAllContent?.length && <ShowAll content={showAllContent} />}
        </span>
      </p>
    )}
  </section>
);
