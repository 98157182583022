export const isToday = (timestamp: number) => {
  const dayStart = new Date();
  dayStart.setHours(0, 0, 0, 0);
  return timestamp >= dayStart.getTime();
};

const weekStartDay = 1; // Monday
export const isThisWeek = (timestamp: number) => {
  const weekStart = new Date();
  const weekDay = weekStart.getDay();
  const shift = weekDay < weekStartDay ? -7 : 0;
  weekStart.setDate(weekStart.getDate() - weekDay + weekStartDay + shift);
  weekStart.setHours(0, 0, 0, 0);
  return timestamp >= weekStart.getTime();
};

export const locale = 'en-FI';
export const timeStr = (timestamp: number) => {
  const date = new Date(timestamp);
  return date.toLocaleTimeString(locale, {
    hour: 'numeric',
    minute: '2-digit',
  });
};

export const dateStr = (timestamp: number) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString(locale, {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: undefined,
  });
};

export const dateTimeStr = (timestamp: number) => {
  return `${dateStr(timestamp)} at ${timeStr(timestamp)}`;
};
