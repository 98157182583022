/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { ComponentProps, FC } from 'react';

export const Button: FC<ComponentProps<'button'>> = ({ onClick, children, ...rest }) => (
  <button
    {...rest}
    onClick={onClick}
    css={css`
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 1rem;
      font-family: var(--font-family);
      font-weight: 700;
      color: var(--inverse-color);
      background: var(--main-color);
      padding: 0.5rem 0.75rem;
      border: 1px solid var(--main-color);
      border-radius: 5rem;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
      -webkit-tap-highlight-color: transparent;

      &:hover,
      &:focus-visible,
      &:not(:focus-visible):focus {
        outline: 0;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), inset 0 0 1px 2px var(--highlight-color);
      }

      :active {
        opacity: 0.8;
      }

      :disabled {
        cursor: initial;
        opacity: 0.75;
      }
    `}
  >
    {children}
  </button>
);
