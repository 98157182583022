/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { FC } from 'react';
import { isThisWeek, timeStr } from '../utils/datetime';
import { ExerciseEntry, ExerciseSettings } from '../utils/types';
import { ContentBlock } from './ContentBlock';

export const ExerciseTracking: FC<{ entries: ExerciseEntry[]; settings: ExerciseSettings }> = ({
  entries,
  settings,
}) => {
  const currentEntries = entries.filter((e) => isThisWeek(e.timestamp));
  const latestEntry = entries[entries.length - 1];
  const exerciseTypes = Object.keys(settings);

  return (
    <ContentBlock
      title="Exercise"
      headerAddition="per week"
      latestEntry={latestEntry && { timestamp: latestEntry.timestamp, description: latestEntry.type }}
      showAllContent={[...currentEntries].reverse().map((e) => (
        <div key={`exercise-${e.timestamp}`}>
          <strong
            css={css`
              margin-right: 0.5rem;
            `}
          >
            {timeStr(e.timestamp)}
          </strong>
          <span>{` ${e.type}`}</span>
        </div>
      ))}
    >
      {exerciseTypes.map((t) => (
        <p
          key={t}
          css={css`
            margin: 0 0 0.5rem;
            font-size: 1.25rem;
          `}
        >
          <span>{`${t.slice(0, 1).toLocaleUpperCase()}${t.slice(1)}: `}</span>
          <span
            css={css`
              font-weight: 700;
            `}
          >{`${currentEntries.reduce((v, e) => (e.type === t ? v + 1 : v), 0)} / ${settings[t]}`}</span>
        </p>
      ))}
    </ContentBlock>
  );
};
